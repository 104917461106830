








































































import {Espesor,GuardarEspesor,EditarEspesor} from '@/entidades/Maestro/Ktb/Espesor';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,decimal} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
@Component
export default class FormularioAddTarifa extends Vue
{
  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Espesor;

  descripcion?:string="";
  espesorId?:number =0;
  pulgada?:number =0;
  tipo?:string ="";
  plantilla:number=0;
  factorHueco:number=0;
  factorHuecoTablon:number=0;

  tipoEspesor:Array<string>=['FINO','GRUESO'];

  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
         pulgada : {decimal},
         plantilla: {required},
         factorHueco :{required},
         factorHuecoTablon :{required}
     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"))
         let response = new ResponseGenerico(false,"");
         let espesor:Espesor = new Espesor(this.espesorId,this.descripcion,this.tipo,this.pulgada,true,this.factorHueco, this.plantilla,this.factorHuecoTablon);
         response = this.action == 1 ?  await GuardarEspesor(espesor) : await EditarEspesor(espesor);
         if(response.isSuccess == true)
         {
             this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
        this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
         this.changeLoading(new Loading(false,""))
     }
    
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE ESPESOR" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE ESPESOR" , 'boton' : "EDITAR"};
 }

 mounted() 
 {
    if(this.modelo!=undefined)
    {
        this.descripcion = this.modelo.descripcion;
        this.espesorId = this.modelo.espesorId;
        this.tipo = this.modelo.tipo;
        this.pulgada = this.modelo.pulgada;
        this.plantilla =  this.modelo.plantilla!;
        this.factorHueco = this.modelo.factorHueco!;
        this.factorHuecoTablon = this.modelo.factorHuecoTablon!;
    }   
 }

}
