import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Espesor
{
    espesorId?:number = 0;
    descripcion?:string ="";
    tipo?:string="";
    pulgada?:number =0;
    estado?:boolean = false;
    factorHueco?:number = 0
    factorHuecoTablon?:number=0;
    plantilla?:number;

    constructor(_espesorId?:number,_descripcion?:string,_tipo?:string,_pulgada?:number, _estado?:boolean,
        _factorHueco?:number,_plantilla?:number,_factorHuecoTablon?:number)
    {
        this.espesorId = _espesorId;
        this.descripcion  =_descripcion;
        this.tipo = _tipo;
        this.pulgada = _pulgada;
        this.estado = _estado;
        this.factorHueco = _factorHueco;
        this.plantilla = _plantilla;
        this.factorHuecoTablon = _factorHuecoTablon;
    }
}

async function  GuardarEspesor(espesor:Espesor):Promise<ResponseGenerico>
{
    return await Save<Espesor>("espesor/Guardar",espesor,mainAxios);
} 

async function  EditarEspesor(espesor:Espesor):Promise<ResponseGenerico>
{
    return await Editar<Espesor>("espesor/Actualizar",espesor,mainAxios);
} 

async function  EditarEstadoEspesor(espesor:Espesor):Promise<ResponseGenerico>
{
    return await Editar<Espesor>("espesor/CambiarEstado",espesor,mainAxios);
} 

async function ObtenerEspesor()
{
    return await Obtener<Espesor>("espesor/Obtener",mainAxios);
}


async function ObtenerSelectEspesor()
{
    return await Obtener<Espesor>("espesor/ObtenerSelect",mainAxios);
}

export 
{
    Espesor,
    GuardarEspesor,
    EditarEspesor,
    EditarEstadoEspesor,
    ObtenerEspesor,
    ObtenerSelectEspesor
}